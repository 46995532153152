import React from 'react';
import { Profile } from './Profile';
import Logo from './Logo';
import { ThemeSwitcher } from './ThemeSwitcher';
import { Box, Burger, Container, Group } from '@mantine/core';
import { AppMenu } from './Menu';

export function MegaHeader({ opened, toggle }: any) {
    return (
        <Box>
            <Container size={'100%'}>
                <Group justify="space-between" h="100%">
                    {/* <Burger
                        opened={opened}
                        onClick={toggle}
                        hiddenFrom="sm"
                        size="sm"
                    /> */}
                    <Logo />
                    <AppMenu />
                    <Group>
                        {/* <LangSwitcher /> */}
                        <Profile />
                        <ThemeSwitcher />
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
                    </Group>
                </Group>
            </Container>
        </Box>
    );
}